// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "19.9.0",
  "buildNumber": "10194",
  "gitSha": "34fca8f7682a65ef5fbd6726d51fd52b0c041bc6",
  "fullVersion": "19.9.0-10194",
  "formattedVersion": "19.9.0 (10194-34fca8f7682a65ef5fbd6726d51fd52b0c041bc6)",
  "copyrightYear": "2019"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/19.9.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/19.9.0/${stripLeadingPrefix(suffix, '#')}`
}
    
